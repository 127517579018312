import request from '../utils/request';

// 查询供应商装备列表
export function getZbList() {
  return request({
    url: '/getZbList?apipost_id=3f778f7fb56001',
    method: 'get',
  });
}

// 查询场景方案列表
export function getPlan() {
  return request({
    url: '/getPlan?apipost_id=284ebe39b004',
    method: 'get',
  });
}

// 查询装备视频列表
export function getZbVideo() {
  return request({
    url: '/getZbVideo?apipost_id=2ebf8f79b01c',
    method: 'get',
  });
}
// 查询同类型产品推荐列表
export function getRecProductType() {
  return request({
    url: '/getRecProductType?apipost_id=1b2a07c79b028',
    method: 'get',
  });
}
// 查询销售记录列表
export function getSales() {
  return request({
    url: '/getSales?apipost_id=1c37fe5f9b02d',
    method: 'get',
  });
}
// 查询装备评价列表
export function getEvaluate() {
  return request({
    url: '/getEvaluate?apipost_id=1ca232bf9b02f',
    method: 'get',
  });
}
// 查询教学中心视频列表
export function getTeaching() {
  return request({
    url: '/getTeaching?apipost_id=2a7e7d2b9b031',
    method: 'get',
  });
}
// 查询产品列表
export function getProducts() {
  return request({
    url: '/getProducts?apipost_id=306ca1139b035',
    method: 'get',
  });
}
