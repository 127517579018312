<template>
    <div>
        <headers />
1231
        <div class="container">
            <el-card>
                <filters />
            </el-card>
            <div class="mt-20 mb-20">
                <el-row :gutter="30">
                    <el-col :span="17">
                        <el-card>
                            <div>
                                <el-button v-for="item in orderBy" :key="item.val"
                                    :type="item.val === orderById ? 'danger' : 'default'"
                                    @click="orderById = item.val">{{
                                        item.label }}
                                    <i class="el-icon-sort" v-if="item.val > 1"></i>
                                </el-button>
                            </div>
                            <div class="product-list">
                                <product-item v-for="item in productList" :key="item.id" :item="item" />
                            </div>
                            <div class="text-center mt-20">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                    :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                                    layout="total, sizes, prev, pager, next, jumper" :total="400">
                                </el-pagination>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="7">
                        <el-card>
                            <div slot="header">
                                <img src="../../assets/img/105.png" class="mr-10">
                                <img src="../../assets/img/106.png">
                            </div>
                            <div class="recommends">
                                <div class="recommend-item" v-for="item in recommendList" :key="item.id">
                                    <el-image :src="item.pic" fit="cover" lazy></el-image>
                                    <div class="recommend-title">{{ item.title }}</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
        <footers />
    </div>
</template>

<script>
import headers from "@/components/header.vue"
import footers from "@/components/footer.vue"
import filters from "./components/filters.vue"
import productItem from "./components/productItem.vue"


import { getRecProductType, getProducts } from "@/api/products"

export default {
    name: "Procucts",
    components: {
        headers,
        footers,
        filters,
        productItem
    },
    data () {
        return {
            orderById: 1,
            orderBy: [
                {
                    val: 1,
                    label: '热门'
                },
                {
                    val: 2,
                    label: '时间'
                },
                {
                    val: 3,
                    label: '价格'
                },
                {
                    val: 4,
                    label: '浏览量'
                },
                {
                    val: 5,
                    label: '销量'
                }
            ],
            currentPage: 1,

            recommendList: [],
            productList: [],
        }
    },
    created () {

    },
    mounted () {
        this.searchRecProductType()
        this.searchProducts()
    },
    methods: {
        async searchRecProductType () {
            const res = await getRecProductType()
            this.recommendList = res.data.data.list
        },
        async searchProducts () {
            const res = await getProducts()
            this.productList = res.data.data.list
        },
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`)
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`)
        }
    }
}
</script>

<style lang="less" scoped>
.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 15px;
    padding-top: 30px;
}

.recommends {
    .recommend-item {
        text-align: center;

        &:not(:last-child) {
            margin-bottom: 60px;
        }

        &:hover {
            /deep/.el-image {
                img {
                    transform: scale(1.1);
                }
            }
        }

        /deep/.el-image {
            width: 315px;
            height: 315px;
            border-radius: 4px;

            img {
                transition: transform .3s linear;
            }
        }

        .recommend-title {
            margin-top: 10px;
            color: #2E3033;
            font-size: 16px;
        }
    }
}
</style>