<template>
    <div>
        <div class="product-item" :class="{ 'diff': item.checked }">
            <div class="product-item-img">
                <el-image :src="item.pic" lazy></el-image>
                <div class="product-item-btn">
                    <el-checkbox v-model="item.checked">对比</el-checkbox>
                </div>
            </div>
            <router-link :to="{ name: 'productsDetails', params: { id: item.id } }" class="product-item-info">
                <div class="product-item-title">
                    <h3 class="text-hide">{{ item.title }}</h3>
                </div>
                <p class="text-gray font-12 mt-10">参考价：
                    <span class="font-20 text-red">￥{{ item.price }}</span>
                </p>
                <div class="flex align-items space-between font-12 mt-10">
                    <el-rate v-model="item.score" :max="5" show-score disabled text-color="#F6C420"
                        score-template="{value}"></el-rate>
                    <span class="text-gray">{{ formattedNumber }}人已看</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "biaoxunItem",
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
        }
    },
    computed: {
        formattedNumber () {
            if (this.item.hits > 999) {
                return '999+'
            } else {
                return this.item.hits.toString()
            }
        },
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.product-item {
    display: block;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid rgba(46, 48, 51, 0.1);

    &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

        .product-item-info {
            .product-item-title {
                h3 {
                    color: #ef312a;
                }
            }
        }

        .product-item-img {
            /deep/.el-image {
                img {
                    transform: scale(1.1);
                }
            }

            .product-item-btn {
                right: 0;
            }
        }


    }

    &.diff {
        .product-item-img {
            .product-item-btn {
                right: 0;
            }
        }
    }

    .product-item-img {
        position: relative;
        width: 100%;
        height: 220px;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;

        /deep/.el-image {
            width: 100%;

            img {
                transition: transform .3s linear;
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .product-item-btn {
            position: absolute;
            right: -100%;
            bottom: 10px;
            height: 30px;
            line-height: 30px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 15px 0px 0px 15px;
            color: #FFFFFF;
            font-size: 14px;
            padding: 0 10px;
            transition: right 0.3s ease;

            /deep/.el-checkbox {
                .el-checkbox__label {
                    color: #FFF;
                }
            }
        }
    }

    .product-item-info {
        display: block;
        padding: 15px;
        text-decoration: none;

        .product-item-title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                color: #2E3033;
                font-size: 16px;
                font-weight: 500;
                /* max-width: 137px; */
                transition: color 0.3s ease;
            }

            span {
                color: rgba(46, 48, 51, .6);
                font-size: 12px;
            }
        }
    }

    /deep/.el-rate {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 2px;
            right: -15px;
            width: 1px;
            height: 15px;
            background: rgba(46, 48, 51, 0.1);
        }

        .el-rate__item {
            .el-rate__icon {
                margin-right: 0;
                font-size: 14px;
            }
        }
    }

}
</style>